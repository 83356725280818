import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

/* eslint-disable quote-props */
const regions = {
  '01': 'Республика Адыгея (Адыгея)',
  '02': 'Республика Башкортостан',
  '03': 'Республика Бурятия',
  '04': 'Республика Алтай',
  '05': 'Республика Дагестан',
  '06': 'Республика Ингушетия',
  '07': 'Кабардино-Балкарская Республика',
  '08': 'Республика Калмыкия',
  '09': 'Карачаево-Черкесская Республика',
  '10': 'Республика Карелия',
  '11': 'Республика Коми',
  '12': 'Республика Марий Эл',
  '13': 'Республика Мордовия',
  '14': 'Республика Саха (Якутия)',
  '15': 'Республика Северная Осетия-Алания',
  '16': 'Республика Татарстан (Татарстан)',
  '17': 'Республика Тыва',
  '18': 'Удмуртская Республика',
  '19': 'Республика Хакасия',
  '20': 'Чеченская Республика',
  '21': 'Чувашская Республика-Чувашия',
  '22': 'Алтайский край',
  '23': 'Краснодарский край',
  '24': 'Красноярский край',
  '25': 'Приморский край',
  '26': 'Ставропольский край',
  '27': 'Хабаровский край',
  '28': 'Амурская область',
  '29': 'Архангельская область',
  '30': 'Астраханская область',
  '31': 'Белгородская область',
  '32': 'Брянская область',
  '33': 'Владимирская область',
  '34': 'Волгоградская область',
  '35': 'Вологодская область',
  '36': 'Воронежская область',
  '37': 'Ивановская область',
  '38': 'Иркутская область',
  '39': 'Калининградская область',
  '40': 'Калужская область',
  '41': 'Камчатский край',
  '42': 'Кемеровская область',
  '43': 'Кировская область',
  '44': 'Костромская область',
  '45': 'Курганская область',
  '46': 'Курская область',
  '47': 'Ленинградская область',
  '48': 'Липецкая область',
  '49': 'Магаданская область',
  '50': 'Московская область',
  '51': 'Мурманская область',
  '52': 'Нижегородская область',
  '53': 'Новгородская область',
  '54': 'Новосибирская область',
  '55': 'Омская область',
  '56': 'Оренбургская область',
  '57': 'Орловская область',
  '58': 'Пензенская область',
  '59': 'Пермский край',
  '60': 'Псковская область',
  '61': 'Ростовская область',
  '62': 'Рязанская область',
  '63': 'Самарская область',
  '64': 'Саратовская область',
  '65': 'Сахалинская область',
  '66': 'Свердловская область',
  '67': 'Смоленская область',
  '68': 'Тамбовская область',
  '69': 'Тверская область',
  '70': 'Томская область',
  '71': 'Тульская область',
  '72': 'Тюменская область',
  '73': 'Ульяновская область',
  '74': 'Челябинская область',
  '75': 'Забайкальский край',
  '76': 'Ярославская область',
  '77': 'Москва',
  '78': 'Санкт-Петербург',
  '79': 'Еврейская автономная область',
  '83': 'Ненецкий автономный округ',
  '86': 'Ханты-Мансийский автономный округ - Югра',
  '87': 'Чукотский автономный округ',
  '89': 'Ямало-Ненецкий автономный округ',
  '91': 'Республика Крым',
  '92': 'Севастополь',
  '98': 'Неопределено',
  '99': 'Значение отсутствует',
}

export const printAdres = (adres, isOther) => {
  if (!adres) return ''

  if (!isOther) {
    if (adres.adrs_Note) {
      return adres.adrs_Note + (adres.adrs_Other ? `. ${adres.adrs_Other}` : '')
    }
    if (adres.Content) {
      return adres.Content
    }
  } else {
    const adresArr = []
    if (adres.adrs_PostalCode) adresArr.push(adres.adrs_PostalCode)
    if (adres.adrs_Region) adresArr.push(regions[adres.adrs_Region])
    if (adres.adrs_District) adresArr.push(`${adres.adrs_District.Type} ${adres.adrs_District.Name}`)
    if (adres.adrs_City) adresArr.push(`${adres.adrs_City.Type} ${adres.adrs_City.Name}`)
    if (adres.adrs_UrbanDistrict) adresArr.push(`${adres.adrs_UrbanDistrict.Type} ${adres.adrs_UrbanDistrict.Name}`)
    if (adres.adrs_SovietVillage) adresArr.push(`${adres.adrs_SovietVillage.Type} ${adres.adrs_SovietVillage.Name}`)
    if (adres.adrs_Locality) adresArr.push(`${adres.adrs_Locality.Type} ${adres.adrs_Locality.Name}`)
    if (adres.adrs_Street) adresArr.push(`${adres.adrs_Street.Type} ${adres.adrs_Street.Name}`)
    if (adres.adrs_Level1) adresArr.push(`${adres.adrs_Level1.Type} ${adres.adrs_Level1.Value}`)
    if (adres.adrs_Level2) adresArr.push(`${adres.adrs_Level2.Type} ${adres.adrs_Level2.Value}`)
    if (adres.adrs_Level3) adresArr.push(`${adres.adrs_Level3.Type} ${adres.adrs_Level3.Value}`)
    if (adres.adrs_Apartment) adresArr.push(`${adres.adrs_Apartment.Type} ${adres.adrs_Apartment.Value}`)

    if (adres.PostalCode) adresArr.push(adres.PostalCode)
    if (adres.Region && adres.Region.Code) adresArr.push(regions[adres.Region.Code])
    if (adres.District) adresArr.push(`${adres.District.Type} ${adres.District.Name}`)
    if (adres.City) adresArr.push(`${adres.City.Type} ${adres.City.Name}`)
    if (adres.UrbanDistrict) adresArr.push(`${adres.UrbanDistrict.Type} ${adres.UrbanDistrict.Name}`)
    if (adres.SovietVillage) adresArr.push(`${adres.SovietVillage.Type} ${adres.SovietVillage.Name}`)
    if (adres.Locality) adresArr.push(`${adres.Locality.Type} ${adres.Locality.Name}`)
    if (adres.Street) adresArr.push(`${adres.Street.Type} ${adres.Street.Name}`)
    if (adres.Level1) adresArr.push(`${adres.Level1.Type} ${adres.Level1.Value}`)
    if (adres.Level2) adresArr.push(`${adres.Level2.Type} ${adres.Level2.Value}`)
    if (adres.Level3) adresArr.push(`${adres.Level3.Type} ${adres.Level3.Value}`)
    if (adres.Apartment) adresArr.push(`${adres.Apartment.Type} ${adres.Apartment.Value}`)

    const otherAdresArr = []
    if (adres.adrs_OKATO) otherAdresArr.push(`ОКАТО ${adres.adrs_OKATO}`)
    if (adres.adrs_KLADR) otherAdresArr.push(`КЛАДР ${adres.adrs_KLADR}`)
    if (adres.Code_OKATO) otherAdresArr.push(`ОКАТО ${adres.Code_OKATO}`)
    if (adres.KLADR) otherAdresArr.push(`КЛАДР ${adres.KLADR}`)
    return adresArr.join(', ') + (otherAdresArr.length > 0 ? ` (${otherAdresArr.join(', ')})` : '')
  }
  return ''
}

export const printAssignationCode = excerptDataObj => {
  if (!excerptDataObj || !excerptDataObj.Assignation || !excerptDataObj.Assignation.flat_AssignationType) return ''
  if (!excerptDataObj.Assignation.flat_AssignationType.flat_AssignationCode) return ''
  const parts = []
  switch (excerptDataObj.Assignation.flat_AssignationType.flat_AssignationCode) {
    case '206001000000': {
      parts.push('Нежилое помещение')
      break
    }
    case '206002000000': {
      parts.push('Жилое  помещение')
      break
    }
    default: break
  }
  if (excerptDataObj.Assignation.flat_AssignationType.flat_TotalAssets) {
    parts.push('общее имущество собственников помещений в многоквартирном дом')
  }
  return parts.join(', ')
}

export const printAssignationType = code => {
  if (!code) return 'данные отсутствуют'
  switch (code) {
    case '205001000000': return 'Квартира'
    case '205002000000': return 'Комната'
    default: return 'данные отсутствуют'
  }
}
export const printLevel = PositionInObject => {
  const levelsArr = []
  let i = 0

  const levelTypes = {
    '01': 'Этаж',
    '02': 'Мансарда',
    '03': 'Мезонин',
    '04': 'Подвал',
    '05': 'Техническое подполье',
    '06': 'Цокольный этаж',
    '07': 'Надстроенный этаж',
    '08': 'Технический этаж',
    '09': 'Чердак',
    '10': 'Антресоль',
    '11': 'Светелка',
    '12': 'Полуподвал',
    '13': 'Антресоль подвала',
    '14': 'Антресоль цокольного этажа',
    '15': 'Чердачная надстройка',
    '16': 'Подземный этаж',
    '17': 'Значение  отсутствует',
  }
  const isNumberOnPlay = PositionInObject && PositionInObject.Position && PositionInObject.Position.NumberOnPlan
  const isLevels = PositionInObject && PositionInObject.Levels && PositionInObject.Levels.Level
  if (isNumberOnPlay && isLevels) return 'данные отсутствуют'

  if (isLevels) {
    for (i = 0; i < PositionInObject.Levels.Level.length; i += 1) {
      const level = PositionInObject.Levels.Level[i]
      const isLevelPlan = level.Position && level.Position.NumberOnPlan
      levelsArr.push(`${levelTypes[level.Type]} №${level.Number}${isLevelPlan ? `, номер на плане этажа ${level.Position.NumberOnPlan}` : ''}`)
    }
  }
  if (isNumberOnPlay) levelsArr.push(`Cооружение, не имеющет этажи, номер на плане ${PositionInObject.Position.NumberOnPlan}`)
  return levelsArr.join(', ')
}

export const printDate = date => {
  if (!date) { return '' }
  const parts = date.split('-')
  if (parts.length !== 3) { return date }
  return `${parts[2]}.${parts[1]}.${parts[0]}`
}

export const printParentCadastralNumbers = parentCadastralNumbers => {
  if (!parentCadastralNumbers) { return '' }
  const numbers = []
  if (parentCadastralNumbers.CadastralNumberFlat) { numbers.push(parentCadastralNumbers.CadastralNumberFlat) }
  if (parentCadastralNumbers.CadastralNumberOKS) { numbers.push(parentCadastralNumbers.CadastralNumberOKS) }
  return numbers.join(', ')
}

export const printConstructors = constructors => {
  if (!constructors || !constructors.Contractor) { return 'данные отсутствуют' }
  if (constructors.Contractor.NCertificate && constructors.Contractor.NCertificate.indexOf('№') === 0) {
    // eslint-disable-next-line no-param-reassign
    constructors.Contractor.NCertificate = constructors.Contractor.NCertificate.replace('№', '').trim()
  }
  return `${constructors.Contractor.FamilyName} ${constructors.Contractor.FirstName} ${constructors.Contractor.Patronymic}`
  + `${constructors.Contractor.NCertificate ? `, №${constructors.Contractor.NCertificate}` : ''}`
  // eslint-disable-next-line no-use-before-define
  + `${constructors.Contractor.Organization && constructors.Contractor.Organization !== null ? ` (${printConstructorOrganizotion(constructors.Contractor.Organization)})` : ''}`
}

export const printState = Obj => {
  if (!Obj || !Obj.State) { return 'данные отсутствуют' }

  let msg = ''
  if (Obj.State === '05') {
    msg = 'Сведения об объекте недвижимости имеют статус "временные"'
    if (Obj.DateExpiry) {
      msg += `. Дата истечения срока действия временного характера - ${printDate(Obj.DateExpiry)}`
    }
  } else if (Obj.State === '07' || Obj.State === '08') {
    msg = 'Объект недвижимости снят с кадастрового учета'
    if (Obj.DateRemoved) {
      msg += ` - ${printDate(Obj.DateRemoved)}`
    }
  } else if (Obj.State === '06') {
    msg = 'Сведения об объекте недвижимости имеют статус "актуальные"'
  } else if (Obj.State === '01') {
    msg = 'Сведения об объекте недвижимости имеют статус "актуальные, ранее учтенные"'
  } else {
    msg = Obj.State
  }
  return msg
}

export const printConstructorOrganizotion = organization => {
  if (!organization) { return '' }
  const parts = []
  if (organization) {
    if (organization.Name) {
      parts.push(organization.Name)
    }
    if (organization.INN) {
      parts.push(organization.INN)
    }
    if (organization.Content) {
      parts.push(organization.Content)
    }
    return parts.join(', ')
  }
  return ''
}

export const printDocV2 = doc => {
  const parts = []
  if (doc.doc_Name) {
    parts.push(`${doc.doc_Name}`)
  } else {
    // eslint-disable-next-line no-use-before-define
    parts.push(docTypes[doc.doc_CodeDocument])
  }

  if (doc.doc_Name) {
    if (doc.doc_Name.indexOf(doc.doc_Number) === -1) {
      parts.push(`№${doc.doc_Number}`)
    } else if (doc.doc_Name.indexOf(`№${doc.doc_Number}`) === -1) {
      parts[parts.length - 1] = parts[parts.length - 1].replace(doc.doc_Number, `№${doc.doc_Number}`)
    }
    parts[parts.length - 1] += ` от ${printDate(doc.doc_Date)}`
  }
  return parts.join(', ')
}

export const encTypes = {
  '022001000000': 'Сервитут',
  '022001001000': 'Публичный сервитут',
  '022001002000': 'Частный сервитут',
  '022002000000': 'Арест',
  '022003000000': 'Запрещение',
  '022004000000': 'Ограничения прав на земельный участок, предусмотренные статьями 56, 56.1 Земельного кодекса Российской Федерации',
  '022004001000': 'Ограничения прав на земельный участок, предусмотренные статьей 56 Земельного кодекса Российской Федерации',
  '022004002000': 'Ограничения прав на земельный участок, предусмотренные статьей 56.1 Земельного кодекса Российской Федерации',
  '022005000000': 'Решение об изъятии земельного участка, жилого помещения',
  '022006000000': 'Аренда (в том числе, субаренда)',
  '022007000000': 'Ипотека',
  '022008000000': 'Ипотека в силу закона',
  '022009000000': 'Безвозмездное (срочное) пользование земельным/лесным участком',
  '022010000000': 'Доверительное управление',
  '022011000000': 'Рента',
  '022012000000': 'Запрет на совершение действий в сфере ГКУ в отношении ОН',
  '022099000000': 'Иные ограничения (обременения) прав',
}

export const printEncumbrance = obj => {
  const parts = []
  if (obj.Encumbrance) {
    if (obj.Encumbrance.Type && encTypes[obj.Encumbrance.Type]) {
      parts.push(encTypes[obj.Encumbrance.Type])
    }

    if (obj.Encumbrance.OwnersRestrictionInFavorem
      && obj.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem
      && obj.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem.length > 0) {
      // eslint-disable-next-line no-use-before-define
      parts.push(printOwnersRestrictionInFavorem(obj.Encumbrance))
    }

    if (obj.Encumbrance.AccountNumber) {
      parts.push(obj.Encumbrance.AccountNumber)
    }

    if (obj.Encumbrance.CadastralNumberRestriction) {
      parts.push(obj.Encumbrance.CadastralNumberRestriction)
    }

    if (obj.Encumbrance.Document) {
      // eslint-disable-next-line no-use-before-define
      parts.push(printDocV2(obj.Encumbrance.Document))
    }
    if (obj.Encumbrance.Duration) {
      // eslint-disable-next-line no-use-before-define
      parts.push(`Срок действия: ${printDuration(obj.Encumbrance.Duration)}`)
    }
  }

  if (obj.State && obj.State === '05') {
    parts.push(printState(obj))
  }

  return parts.join('. ')
}

export const printOwnersRestrictionInFavorem = enc => {
  if (enc.OwnersRestrictionInFavorem
    && enc.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem
    && enc.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem.length > 0) {
    const partsRestOwners = []
    for (let i = 0; i < enc.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem.length; i += 1) {
      const owner = enc.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem[i]
      // eslint-disable-next-line no-use-before-define
      partsRestOwners.push(printOwner(owner))
    }
    return `Лица, в пользу которых ограничиваются (обременяются) права: ${partsRestOwners.join('; ')}`
  }
  return ''
}

export const printOwner = owner => {
  if (!owner) { return '' }

  if (owner.Governance) {
    if (owner.Governance.Name) return owner.Governance.Name
    if (owner.Governance.Content) return owner.Governance.Content
    const parts = []
    if (owner.Governance.OKATO_Code) parts.push(`ОКАТО ${owner.Governance.OKATO_Code}`)
    if (owner.Governance.RegNumber) parts.push(owner.Governance.RegNumber)
    if (owner.Governance.RegDate) parts.push(printDate(owner.Governance.RegDate))
    if (owner.Governance.Country) parts.push(owner.Governance.Country)
    if (owner.Governance.Address) parts.push(owner.Governance.Address)
    if (parts.length > 0) parts.join(', ')
  }
  if (owner.Organization) {
    if (owner.Organization.Content || owner.Organization.Name || owner.Organization.INN) {
      if (owner.Organization.Content) return owner.Organization.Content
      const parts = []
      if (owner.Organization.Name) {
        parts.push(owner.Organization.Name)
      }
      if (owner.Organization.INN) {
        parts.push(owner.Organization.INN)
      }
      return parts.join(', ', parts)
    }
  }
  if (owner.Person) {
    if (owner.Person.FIO) return `${owner.Person.FIO.Surname} ${owner.Person.FIO.First} ${owner.Person.FIO.Patronymic}`
    if (owner.Person.Content) return owner.Person.Content
    const pParts = []
    if (owner.Person.FamilyName) pParts.push(owner.Person.FamilyName)
    if (owner.Person.FirstName) pParts.push(owner.Person.FirstName)
    if (owner.Person.Patronymic) pParts.push(owner.Person.Patronymic)
    if (pParts.length > 0) return pParts.join(' ')
  }
  return 'данные о правообладателе отсутствуют'
}

export const printStrArray = (arr, delimiter = ', ') => {
  if (!arr) return 'данные отсутствуют'
  return arr.join(delimiter)
}

export const oldNumberTypes = {
  '01': 'Инвентарный номер',
  '02': 'Условный номер',
  '03': 'Кадастровый номер',
  '99': 'Иной номер',
}

export const printOldNumbers = flat => {
  if (!flat || !flat.OldNumbers || !flat.OldNumbers.num_OldNumber) return 'данные отсутствуют'
  return flat.OldNumbers.num_OldNumber.map(ii => `${oldNumberTypes[ii.Type]}: ${ii.Number}`).join('; ')
}

export const objectTypes = {
  '002001002000': 'Здание',
  '002001004000': 'Сооружение',
  '002001004001': 'Линейное сооружение',
  '002001004002': 'Условная часть линейного сооружения',
  '002001005000': 'Объект незавершенного строительства',
  '002001003000': 'Помещение',
}

export const printOffspringObjects = flat => {
  if (!flat) return 'данные отсутствуют'
  let arr = []
  if (flat.OffspringObjects && flat.OffspringObjects.CadastralNumber) {
    arr = flat.OffspringObjects.CadastralNumber
  } else if (flat.AllOffspringParcel && flat.AllOffspringParcel.CadastralNumber) {
    arr = flat.AllOffspringParcel.CadastralNumber
  }
  if (!arr || arr.length === 0) return 'данные отсутствуют'

  return printStrArray(arr)
}

export const printObjectType = objectType => {
  if (!objectType) return 'данные отсутствуют'
  return objectTypes[objectType]
}

export const printDoc = doc => {
  if (!doc) return ''
  if (doc.Content) return doc.Content
  const parts = []
  if (doc.Name) parts.push(doc.Name)
  if (doc.Series) parts.push(doc.Series)
  if (doc.Number) parts.push(doc.Number)
  if (doc.IssueOrgan) parts.push(doc.IssueOrgan)
  if (doc.Date) parts.push(printDate(doc.Date))
  if (parts.length > 0) return parts.join(', ')
  return ''
}

export const printDuration = duration => {
  if (!duration) return 'данные отсутствуют'
  const parts = []
  if (duration.Started && (!duration.Term || duration.Term.indexOf(duration.Started) === -1)) { parts.push(`c ${printDate(duration.Started)}`) }
  if (duration.Stopped && (!duration.Term || duration.Term.indexOf(duration.Stopped) === -1)) { parts.push(`по ${printDate(duration.Stopped)}`) }
  if (duration.Term) { parts.push(duration.Term) }
  return parts.join(' ')
}

export const pn = value => {
  try {
    return value
  } catch {
    return ''
  }
}

export const printCadastrCost = cost => {
  if (!cost) return 'данные отсутствуют'
  if (!cost.Value) return 'данные отсутствуют'
  return `${cost.Value} руб`
}

export const culturals = {
  '207001000000': 'Памятник',
  '207002000000': 'Ансамбль',
  '207003000000': 'Достопримечательное место',
  '207004000000': 'Объект археологического наследия',
}

export const docTypes = {
  '008001000000': 'Документы, удостоверяющие личность физического лица',
  '008001001000': 'Паспорт гражданина Российской Федерации',
  '008001002000': 'Паспорт гражданина СССР',
  '008001003000': 'Паспорт гражданина иностранного государства',
  '008001004000': 'Общегражданский заграничный паспорт',
  '008001005000': 'Заграничный паспорт Министерства морского флота',
  '008001006000': 'Дипломатический паспорт',
  '008001007000': 'Паспорт моряка (удостоверение личности моряка)',
  '008001008000': 'Военный билет военнослужащего',
  '008001009000': 'Временное удостоверение, выданное взамен военного билета',
  '008001010000': 'Удостоверение личности офицера Министерства обороны Российской Федерации, Министерства внутренних дел Российской Федерации и других воинских формирований с приложением справки о прописке (регистрации) Ф-33',
  '008001011000': 'Свидетельство о рождении',
  '008001012000': 'Свидетельство о рассмотрении ходатайства о признании беженцем на территории Российской Федерации по существу',
  '008001013000': 'Вид на жительство иностранного гражданина или лица без гражданства',
  '008001014000': 'Справка об освобождении из мест лишения свободы',
  '008001015000': 'Временное удостоверение личности гражданина Российской Федерации',
  '008001016000': 'Удостоверение вынужденного переселенца',
  '008001017000': 'Разрешение на временное проживание в Российской Федерации',
  '008001018000': 'Удостоверение беженца в Российской Федерации',
  '008001019000': 'Свидетельство о рассмотрении ходатайства о признании лица вынужденным переселенцем',
  '008001020000': 'Свидетельство о предоставлении временного убежища на территории Российской Федерации',
  '008001099000': 'Иные документы, предусмотренные законодательством Российской Федерации или признаваемые в соответствии с международным договором Российской Федерации в качестве документов, удостоверяющих личность',
  '008002000000': 'Документы, идентифицирующие юридическое лицо',
  '008002001000': 'Свидетельство о государственной регистрации юридического лица',
  '008002002000': 'Свидетельство о внесении записей в ЕГРЮЛ',
  '008002004000': 'Устав',
  '008002005000': 'Учредительный договор',
  '008002006000': 'Положение (общее положение об организации)',
  '008002007000': 'Свидетельство (или уведомление) о постановке на учёт в налоговом органе с указанием ИНН',
  '008002008000': 'Выписка из ЕГРЮЛ',
  '008002099000': 'Иной документ',
  '555001000000': 'Квитанция',
  '555002000000': 'Платёжное поручение',
  '555003000000': 'Чек',
  '555004000000': 'Подтверждение электронного платежа',
  '555005000000': 'Документ, подтверждающий право заявителя на безвозмездное получение сведений',
  '558100000000': 'ЗАЯВЛЕНИЯ',
  '558101000000': 'Заявления и запросы, связанные с государственным кадастровым учётом',
  '558101010000': 'Запрос о предоставлении сведений, внесённых в государственный кадастр недвижимости, в виде кадастровой выписки об объекте недвижимости, территориальной зоне, зоне с особыми условиями использования территории, территории объекта культурного наследия, включенного в единый государственный реестр объектов культурного наследия (памятников истории и культуры) народов Российской Федерации, границах между субъектами Российской Федерации, границах муниципальных образований и границах населённых пунктов, кадастрового паспорта объекта недвижимости и кадастрового плана территории, кадастровой справки о кадастровой стоимости объекта недвижимости',
  '558101020000': 'Заявление о постановке на государственный кадастровый учёт объекта недвижимости',
  '558101030000': 'Заявление о государственном кадастровом учёте изменений объекта недвижимости',
  '558101040000': 'Заявление о внесении в Единый государственный реестр объектов капитального строительства (ЕГРОКС) сведений об объекте капитального строительства (ОКС)',
  '558101050000': 'Запрос о предоставлении сведений об ОКС',
  '558101060000': 'Заявление о снятии с государственного кадастрового учёта объекта недвижимости',
  '558101070000': 'Заявление о внесении в государственный кадастр недвижимости сведений о ранее учтённом объекте недвижимости',
  '558101080000': 'Заявление об исправлении кадастровой ошибки',
  '558101090000': 'Заявление об исправлении технической ошибки в сведениях государственного кадастра недвижимости',
  '558101090100': 'Заявление об исправлении технической ошибки в сведениях государственного кадастра недвижимости об объекте недвижимости',
  '558101090200': 'Заявление об исправлении технической ошибки в сведениях государственного кадастра недвижимости о прохождении участка Государственной границы Российской Федерации; о границах между субъектами Российской Федерации; о границах муниципальных образований; о границах населённых пунктов; о территориальных зонах и зонах с особыми условиями использования территорий; о картографической и геодезической основах государственного кадастра недвижимости',
  '558101100000': 'Заявление о представлении дополнительных документов на государственный кадастровый учёт',
  '558101110000': 'Внутриведомственный запрос информации государственного кадастра недвижимости',
  '558101120000': 'Запрос о предоставлении копии документа, на основании которого сведения об объекте недвижимости внесены в государственный кадастр недвижимости, или копий документов, помещённых в кадастровое дело',
  '558101130000': 'Запрос о предоставлении сведений, внесённых в государственный кадастр недвижимости, посредством обеспечения доступа к информационному ресурсу, содержащему сведения государственного кадастра недвижимости',
  '558101140000': 'Запрос о предоставлении перечня объектов недвижимости, подлежащих государственной кадастровой оценке',
  '558101150000': 'Заявление о прекращении осуществления кадастрового учёта',
  '558101160000': 'Межведомственный запрос документов в органах государственной власти и органах местного самоуправления',
  '558101170000': 'Запрос документов о ранее учтённом объекте недвижимости в органах государственной власти, органах местного самоуправления или органах и организациях по государственному техническому учёту и (или) технической инвентаризации',
  '558101180000': 'Запрос об уточнении данных, представленных органом государственной власти или органом местного самоуправления в порядке информационного взаимодействия',
  '558102000000': 'Заявления и запросы, связанные с регистрацией прав',
  '558102010000': 'Заявление о государственной регистрации права на недвижимое имущество, сделки с ним, ограничения (обременения), перехода, прекращения права на недвижимое имущество',
  '558102020000': 'Заявление о государственной регистрации прекращения права (ограничения (обременения) права)',
  '558102030000': 'Заявление о внесении изменений в ЕГРП',
  '558102040000': 'Заявление об исправлении технической ошибки',
  '558102050000': 'Заявление о представлении дополнительных документов для государственной регистрации прав',
  '558102060000': 'Заявление о приостановлении государственной регистрации прав на недвижимое имущество и сделок с ним',
  '558102070000': 'Заявление о возобновлении государственной регистрации',
  '558102080000': 'Заявление о прекращении государственной регистрации',
  '558102090000': 'Заявление о повторной выдаче свидетельства о государственной регистрации права',
  '558102100000': 'Запрос о предоставлении сведений, содержащихся в Едином государственном реестре прав на недвижимое имущество и сделок с ним',
  '558102110000': 'Заявление о постановке на учёт бесхозяйных недвижимых вещей',
  '558102120000': 'Заявление о выдаче закладной (новой закладной, дубликата закладной, аннулированной закладной)',
  '558102130000': 'Запрос о выдаче копий договоров и иных документов, выражающих содержание односторонних сделок, совершенных в простой письменной форме',
  '558102140000': 'Заявление о приостановлении государственной регистрации, возврате документов без проведения государственной регистрации, представлении дополнительных документов, о возобновлении государственной регистрации, ранее приостановленной по заявлению правообладателя, стороны (сторон) сделки, лица, чьё право ограничивается (обременяется), лица, в пользу которого право ограничивается (обременяется), законного владельца закладной (их представителя)',
  '558102150000': 'Заявление о повторной выдаче нового свидетельства о государственной регистрации права, выдаче закладной, внесении изменений в запись Единого государственного реестра прав на недвижимое имущество и сделок с ним, погашении регистрационной записи об ипотеке',
  '558102160000': 'Заявление о принятии объекта недвижимого имущества вновь во владение, пользование и распоряжение (в собственность)',
  '558102170000': 'Заявление о внесении в Единый государственный реестр прав на недвижимое имущество и сделок с ним отметки о наличии судебного спора в отношении зарегистрированного права',
  '558102180000': 'Заявление об исправлении технической ошибки в записях Единого государственного реестра прав на недвижимое имущество и сделок с ним, о наличии возражения в отношении зарегистрированного права на объект недвижимости, о погашении записи в Едином государственном реестре прав на недвижимое имущество и сделок с ним о наличии возражения в отношении зарегистрированного права на объект недвижимости, о невозможности государственной регистрации перехода, ограничения (обременения), прекращения права на объект недвижимости без личного участия собственника (его законного представителя), об отзыве ранее представленного заявления о невозможности государственной регистрации перехода, ограничения (обременения), прекращения права на объект недвижимости без личного участия собственника (его законного представителя)',
  '558102190000': 'Заявление о скреплении добавочного листа (добавочных листов) с закладной',
  '558102200000': 'Запрос о предоставлении сведений, содержащихся в Едином государственном реестре прав на недвижимое имущество и сделок с ним, посредством обеспечения доступа к информационному ресурсу, содержащему сведения Единого государственного реестра прав на недвижимое имущество и сделок с ним',
  '558102210000': 'Заявление о государственной регистрации законного владельца закладной',
  '558102220000': 'Заявление о внесении изменений в запись Единого государственного реестра прав на недвижимое имущество и сделок с ним, погашении регистрационной записи об ипотеке',
  '558103000000': 'Заявления о государственном учёте зданий, сооружений, помещений, объектов незавершённого строительства (объектов учёта) и запросы о предоставлении сведений, внесённых в Единый государственный реестр',
  '558103010000': 'Заявление о постановке на государственный учёт объекта учёта',
  '558103020000': 'Заявление о внесении в ЕГРОКС сведений о ранее учтённом объекте капитального строительства',
  '558103030000': 'Заявление о государственном учёте изменений объекта учёта',
  '558103040000': 'Заявление о снятии с государственного учёта объекта учёта',
  '558103050000': 'Заявление об исправлении технической ошибки',
  '558103060000': 'Заявление об исключении сведений из ЕГРОКС, носящих временный характер',
  '558103070000': 'Запрос о предоставлении копии документа, на основании которого сведения об объекте внесены в Единый государственный реестр',
  '558103080000': 'Запрос о предоставлении сведений, внесенных в Единый государственный реестр',
  '558103090000': 'Заявление о представлении дополнительных документов на государственный учёт',
  '558104000000': 'Заявление о возврате платежа',
  '558199000000': 'Иное заявление',
  '558200000000': 'ДОКУМЕНТЫ, СОДЕРЖАЩИЕ ОПИСАНИЕ ОБЪЕКТА',
  '558201000000': 'Описание земельных участков',
  '558202000000': 'Землеустроительное дело',
  '558203000000': 'Межевой план',
  '558204000000': 'Документ (копия), на основании которого сведения об объекте недвижимости внесены в ГКН',
  '558205000000': 'Технический паспорт объекта учёта',
  '558205010000': 'Технический паспорт домовладения',
  '558205020000': 'Технический паспорт здания (строения)',
  '558205030000': 'Технический паспорт жилого помещения (квартиры)',
  '558205040000': 'Технический паспорт объекта индивидуального жилищного строительства',
  '558205050000': 'Технический паспорт сооружения',
  '558205060000': 'Технический паспорт объекта незавершённого строительства',
  '558206000000': 'Выписка из технического паспорта',
  '558206010000': 'Выписка из технического паспорта на здание (строение)',
  '558206020000': 'Выписка из технического паспорта БТИ',
  '558207000000': 'Документ (копия), подтверждающий разрешение земельного спора о согласовании местоположения границ земельного участка',
  '558208000000': 'Документ (копия), подтверждающий принадлежность земельного участка к определенной категории земель',
  '558209000000': 'Документ (копия), подтверждающий установленное разрешенное использование земельного участка',
  '558210000000': 'Кадастровый план земельного участка (выписка из государственного земельного кадастра)',
  '558211000000': 'Планы иных объектов недвижимости',
  '558211010000': 'Технический план здания, сооружения, помещения либо объекта незавершённого строительства',
  '558211020000': 'План участка лесного фонда',
  '558211030000': 'План объекта недвижимости',
  '558211040000': 'Экспликация к поэтажному плану',
  '558212000000': 'Декларация об объекте недвижимости',
  '558213000000': 'Кадастровый паспорт объекта недвижимости',
  '558213010000': 'Кадастровый паспорт земельного участка',
  '558213020000': 'Кадастровый паспорт помещения (выписка из ГКН)',
  '558213030000': 'Кадастровый паспорт здания, сооружения, объекта незавершённого строительства',
  '558214000000': 'Кадастровая выписка',
  '558214010000': 'Кадастровая выписка о земельном участке',
  '558214020000': 'Кадастровая выписка о здании, сооружении, объекте незавершённого строительства',
  '558214030000': 'Кадастровая выписка о территориальной зоне, зоне с особыми условиями использования территории, территории объекта культурного наследия, включенного в единый государственный реестр объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558214040000': 'Кадастровая выписка о границах между субъектами Российской Федерации, границах муниципальных образований и границах населенных пунктов',
  '558216000000': 'Документ, подтверждающий изменение назначения здания, помещения или сооружения, наименования сооружения',
  '558217000000': 'Кадастровый план территории',
  '558218000000': 'Кадастровая справка о кадастровой стоимости объекта недвижимости',
  '558219000000': 'Акт обследования, подтверждающий прекращение существования объекта недвижимости',
  '558220000000': 'Документы, в виде которых предоставляются сведения Единого государственного реестра',
  '558220010000': 'Выписка из Реестра, содержащая относящиеся к информации ограниченного доступа сведения об объекте учета',
  '558220020000': 'Справка, содержащая, внесённые в Единый государственный реестр сведения о прекращении существования объекта учёта',
  '558220030000': 'Выписка из Реестра, подтверждающая факт отсутствия в Реестре сведений об объекте учета',
  '558221000000': 'ПРОЧИЕ',
  '558221010000': 'Решение о необходимости устранения кадастровой ошибки',
  '558221020000': 'Международный договор Российской Федерации, в соответствии с которым установлено или изменено прохождение государственной границы Российской Федерации',
  '558221030000': 'Документ, содержащий необходимые для внесения в государственный кадастр недвижимости сведения о границах между субъектами Российской Федерации, границах муниципального образования, границах населённого пункта',
  '558221040000': 'Документ, содержащий необходимые для внесения в государственный кадастр недвижимости сведения об установлении или изменении территориальной зоны или зоны с особыми условиями использования территорий, либо об отмене установления такой зоны',
  '558221050000': 'Решение о присвоении, изменении адреса',
  '558221060000': 'Документы органов, осуществляющих ведение лесного и водного кадастра, содержащие сведения о природных объектах',
  '558221070000': 'Документ, содержащий сведения об адресе объекта недвижимости',
  '558221080000': 'Акт об утверждении результатов определения кадастровой стоимости',
  '558221090000': 'Документы – основания внесения отметки, предусмотренной пунктом 14.1 части 2 статьи 7 221-ФЗ «О государственном кадастре недвижимости»',
  '558221090100': 'Акт и (или) договор о предоставлении находящегося в государственной или муниципальной собственности участка под наемный дом',
  '558221090200': 'Решение собственника земельного участка, находящегося в частной собственности, о строительстве наемного дома либо договор, заключенный для создания, эксплуатации наемного дома',
  '558221090300': 'Акт о предоставлении участка для строительства наемного дома',
  '558221090400': 'Акт о предоставлении участка для освоения территории в целях строительства и эксплуатации наемного дома',
  '558221090500': 'Договор о предоставлении участка для строительства наемного дома',
  '558221090600': 'Договор о предоставлении участка для освоения территории в целях строительства и эксплуатации наемного дома',
  '558221090700': 'Решение собственника земельного участка, находящегося в частной собственности, о строительстве наемного дома',
  '558221090800': 'Договор о предоставлении поддержки для создания и эксплуатации наемного дома социального использования',
  '558221990000': 'Иной документ, содержащий описание объекта',
  '558222000000': 'Документ, воспроизводящий сведения, содержащиеся в международном договоре Российской Федерации, в соответствии с которым установлено или изменено прохождение государственной границы Российской Федерации, а также сведения о федеральном законе, которым ратифицирован международный договор Российской Федерации, и об официальном опубликовании такого федерального закона',
  '558223000000': 'Соглашение об изменении границ между субъектами Российской Федерации',
  '558224000000': 'Закон субъекта Российской Федерации об установлении или изменении границ муниципальных образований либо о преобразовании муниципальных образований',
  '558225000000': 'Акт уполномоченного органа об установлении или изменении границ населённых пунктов',
  '558226000000': 'Документ, воспроизводящий необходимые для внесения в государственный кадастр недвижимости сведения о границах между субъектами Российской Федерации, границах муниципального образования, границах населённого пункта, содержащиеся в утверждённых органами власти документах',
  '558227000000': 'Решение об установлении или изменении границ зон с особыми условиями использования территорий',
  '558228000000': 'Правовой акт, которым утверждены или изменены правила землепользования и застройки',
  '558229000000': 'Документ, подтверждающий изменение назначения помещения',
  '558230000000': 'Решение о переводе жилого помещения в нежилое помещение, нежилого помещения в жилое помещение',
  '558231000000': 'Акт приемочной комиссии, подтверждающий завершение переустройства и (или) перепланировки и (или) иных работ',
  '558232000000': 'Документы, воспроизводящие сведения об изменении назначения помещения',
  '558233000000': 'Документ, воспроизводящий сведения, содержащиеся в решении о переводе жилого помещения в нежилое помещение, нежилого помещения в жилое помещение',
  '558234000000': 'Документ, воспроизводящий сведения, содержащиеся в акте приемочной комиссии, подтверждающем завершение переустройства и (или) перепланировки и (или) иных работ',
  '558235000000': 'Документ, подтверждающий изменение назначения здания',
  '558236000000': 'Документ, подтверждающий изменение назначения сооружения',
  '558237000000': 'Решение о предоставлении разрешения на условно разрешенный вид использования',
  '558238000000': 'Решение об определении (изменении) вида разрешенного использования',
  '558239000000': 'Документ (копия), воспроизводящий сведения о предоставлении разрешения на условно разрешенный вид использования, об определении (изменении) вида разрешенного использования',
  '558240000000': 'Документ, воспроизводящий сведения, содержащиеся в решении о присвоении, изменении адреса',
  '558241000000': 'Документ, воспроизводящий содержащиеся в разрешении на ввод объекта капитального строительства в эксплуатацию сведения',
  '558242000000': 'Документ, воспроизводящий сведения, содержащиеся в реестре объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558243000000': 'Документ, воспроизводящий сведения, содержащиеся в акте Правительства Российской Федерации об исключении объекта недвижимости из единого государственного реестра объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558244000000': 'Документ, содержащий сведения о дате поступления документов, предусмотренных статьей 17 Федерального закона "Об объектах культурного наследия (памятниках истории и культуры) народов Российской Федерации", в отношении объекта недвижимости',
  '558245000000': 'Решение органа охраны объектов культурного наследия об отказе включить объект недвижимости в единый государственный реестр объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558246000000': 'Решение Правительства Российской Федерации об исключении объекта культурного наследия из единого государственного реестра объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558247000000': 'Документ, воспроизводящий сведения о наименовании водного объекта и установленных параметрах водоохранных зон и прибрежных защитных полос, зон затопления, подтопления и других зон с особыми условиями их использования',
  '558248000000': 'Документ, воспроизводящий содержащиеся в государственном лесном реестре сведения в отношении лесного участка',
  '558249000000': 'Документ, воспроизводящий сведения, содержащиеся во вступившем в силу акте об утверждении результатов определения кадастровой стоимости',
  '558250000000': 'Документ, воспроизводящий сведения, содержащиеся в решении об установлении или изменении границ зон с особыми условиями использования территорий',
  '558251000000': 'Документ, воспроизводящий сведения, содержащиеся в правовом акте, которым утверждены или изменены правила землепользования и застройки, включая сведения о территориальных зонах',
  '558252000000': 'Карта (план) объекта землеустройства',
  '558253000000': 'Декларация заинтересованного лица о выбранном виде разрешенного использования земельного участка',
  '558254000000': 'Документы, определяющие (определявшие) в соответствии с законодательством Российской Федерации местоположение границ земельного участка (земельных участков)',
  '558254010000': 'Землеустроительная документация',
  '558254020000': 'Лесоустроительная документация',
  '558254030000': 'Документы градостроительного зонирования (Правила землепользования и застройки)',
  '558254040000': 'Документация по планировке территории (проекты межевания территорий), в т.ч. документ по планировке территории в планируемых границах искусственного земельного участка',
  '558254040100': 'Проект межевания территории',
  '558254040200': 'Решение об утверждении проекта межевания территории',
  '558254040300': 'Документ, воспроизводящий сведения, содержащиеся в решении об утверждении проекта межевания территории',
  '558254050000': 'Копия проекта межевания земельных участков',
  '558254060000': 'Акт отвода земельных участков в натуре',
  '558254070000': 'Проект границ земельных участков',
  '558254080000': 'Проект (копии отдельных составных частей) организации и застройки территорий садоводческих, огороднических или дачных некоммерческих объединений граждан',
  '558254090000': 'Проект (копии отдельных составных частей) перераспределения сельскохозяйственных угодий и иных земель сельскохозяйственного назначения',
  '558254100000': 'Решение о предварительном согласовании мест размещения объектов',
  '558254110000': 'Копия решения общего собрания участников долевой собственности на земельный участок из земель сельскохозяйственного назначения об утверждении проекта межевания земельных участков',
  '558254120000': 'Копия документа, подтверждающего согласование проекта межевания земельного участка',
  '558254130000': 'Возражения заинтересованных лиц по поводу местоположения границ земельного участка',
  '558254140000': 'Документ, свидетельствующий о снятии возражений заинтересованных лиц по поводу местоположения границ земельного участка',
  '558254150000': 'Схема расположения земельных участков на кадастровых планах или кадастровых картах соответствующих территорий',
  '558254990000': 'Прочие документы, определяющие (определявшие) в соответствии с законодательством Российской Федерации местоположение границ земельного участка (земельных участков)',
  '558255000000': 'Картографические материалы',
  '558256000000': 'Материалы картографо-геодезического фонда',
  '558257000000': 'Документы, устанавливающие предельные минимальные и максимальные размеры, соответствующие виду разрешенного использования земельного участка',
  '558257010000': 'Федеральный закон',
  '558257020000': 'Закон субъекта Российской Федерации',
  '558257030000': 'Нормативный правовой акт органа местного самоуправления',
  '558258000000': 'Документ, содержащий информацию о ранее присвоенных государственных учетных номерах объекта недвижимости',
  '558259000000': 'Протокол образования земельных участков',
  '558260000000': 'Чертеж (абрис) помещения',
  '558261000000': 'Проектная документация здания',
  '558262000000': 'Проектная документация сооружения',
  '558263000000': 'Решение комиссии по рассмотрению споров о результатах определения кадастровой стоимости',
  '558264000000': 'Решение суда',
  '558265000000': 'Документ о редакционно-контрольном просмотре правильности отображения линии государственной границы Российской Федерации на картографических материалах, предназначенных для размещения на Публичной кадастровой карте',
  '558266000000': 'Документ, содержащий сведения о лесах, водных объектах и об иных природных объектах',
  '558267000000': 'Правовой акт, которым утвержден или изменен генеральный план',
  '558268000000': 'Документ, воспроизводящий сведения, содержащиеся в правовом акте, которым утвержден или изменен генеральный план',
  '558269000000': 'Решение о переводе земельного участка из одной категории земель',
  '558270000000': 'Решение об отнесении земельного участка к определенной категории земель',
  '558271000000': 'Документ, воспроизводящий сведения, содержащиеся в решении об отнесении земельного участка к определенной категории земель или о переводе земельного участка из одной категории земель в другую',
  '558272000000': 'Документ об установлении (изменении) границ территории объекта культурного наследия или зон его охраны',
  '558273000000': 'Текстовое и графическое описание местоположения границ территории объекта культурного наследия или зон его охраны с перечнем координат характерных точек этих границ',
  '558274000000': 'Документ, воспроизводящий сведения о присвоении адресов объектам адресации, об изменении или аннулировании адресов объектов адресации',
  '558275000000': 'Решение о включении объекта недвижимости в перечень выявленных объектов культурного наследия',
  '558276000000': 'Решение об отказе во включении объекта в перечень выявленных объектов культурного наследия',
  '558277000000': 'Решение о включении выявленного объекта культурного наследия в единый государственный реестр объектов культурного наследия (памятников истории и культуры) народов Российской Федерации',
  '558278000000': 'Решение Правительства Российской Федерации о создании особой экономической зоны',
  '558279000000': 'Решение Правительства Российской Федерации об увеличении площади такой особой экономической зоны',
  '558280000000': 'Решение Правительства Российской Федерации о досрочном прекращении особой экономической зоны',
  '558281000000': 'Соглашение о создании особой экономической зоны',
  '558282000000': 'Документ, воспроизводящий сведения, содержащиеся в соглашении о создании особой экономической зоны или в решении об увеличении площади особой экономической зоны',
  '558283000000': 'Документ, воспроизводящий сведения, содержащиеся в решении об увеличении площади особой экономической зоны',
  '558284000000': 'Уведомление о досрочном прекращении существования особой экономической зоны',
  '558285000000': 'Документ, воспроизводящий сведения о реквизитах акта или договора органа государственной власти или органа местного самоуправления, предусматривающих предоставление земельного участка для строительства либо освоения территории с целью строительства и эксплуатации наемного дома',
  '558286000000': 'Описание местоположения границ особой экономической зоны',
  '558299000000': 'Иной документ, содержащий описание объекта',
  '558300000000': 'ДОКУМЕНТЫ, СОДЕРЖАЩИЕ ОПИСАНИЕ ЗАЯВИТЕЛЯ ИЛИ ЕГО ПРЕДСТАВИТЕЛЯ',
  '558301000000': 'Документы, подтверждающие полномочия представителя',
  '558301010000': 'Доверенность',
  '558301020000': 'Свидетельство о рождении',
  '558301030000': 'Свидетельство об усыновлении',
  '558301040000': 'Решение суда',
  '558301050000': 'Определение суда',
  '558301060000': 'Протокол об избрании (единоличного исполнительного органа юридического лица)',
  '558301070000': 'Выписка из протокола об избрании (единоличного исполнительного органа юридического лица)',
  '558301080000': 'Приказ о назначении (руководителя или иного уполномоченного лица)',
  '558301090000': 'Решение органов опеки и попечительства',
  '558301100000': 'Договор коммерческого представительства',
  '558301110000': 'Договор доверительного управления',
  '558301120000': 'Постановление судебного пристава-исполнителя',
  '558301130000': 'Протокол общего собрания',
  '558301140000': 'Акт органа государственной власти или органа местного самоуправления, подтверждающий полномочия представителя заявителя',
  '558301990000': 'Иной документ',
  '558400000000': 'ДОКУМЕНТЫ О ПРАВАХ, СДЕЛКАХ, ОГРАНИЧЕНИЯХ (ОБРЕМЕНЕНИЯХ)',
  '558401000000': 'Правоустанавливающие и правоудостоверяющие документы',
  '558401010000': 'Договоры / соглашения',
  '558401010100': 'Сделки об отчуждении',
  '558401010101': 'Договор купли-продажи',
  '558401010102': 'Договор мены',
  '558401010103': 'Договор дарения',
  '558401010104': 'Договор ренты',
  '558401010105': 'Договор пожизненного содержания с иждивением',
  '558401010106': 'Договор приватизации (договор передачи объекта недвижимости в собственность)',
  '558401010200': 'Другие сделки',
  '558401010201': 'Договор участия в долевом строительстве',
  '558401010202': 'Соглашение об определении (перераспределении) долей в праве общей долевой собственности',
  '558401010203': 'Брачный договор',
  '558401010204': 'Соглашение об отступном',
  '558401010205': 'Соглашение о разделе имущества',
  '558401010206': 'Соглашение о выделе доли',
  '558401010207': 'Инвестиционный договор (договор об инвестировании)',
  '558401010208': 'Мировое соглашение',
  '558401010209': 'Договор аренды (субаренды)',
  '558401010210': 'Договор об ипотеке',
  '558401010211': 'Договор безвозмездного срочного пользования земельным участком',
  '558401010212': 'Кредитный договор',
  '558401010213': 'Договор займа',
  '558401010214': 'Соглашение об установлении сервитута',
  '558401010215': 'Соглашение об изменении содержания закладной',
  '558401010216': 'Дополнительное соглашение (соглашение об изменении условий договора) к договору',
  '558401010217': 'Соглашение о расторжении договора',
  '558401010218': 'Соглашение об уступке прав требования по договору (договор о передаче прав и обязанностей)',
  '558401010219': 'Договор простого товарищества (договор о совместной деятельности)',
  '558401010220': 'Государственный контракт',
  '558401010299': 'Иная сделка',
  '558401020000': 'Акты органов государственной власти или органов местного самоуправления',
  '558401020100': 'Федеральный закон',
  '558401020200': 'Указ Президента Российской Федерации',
  '558401020300': 'Постановление Правительства Российской Федерации',
  '558401020400': 'Закон (законы Российской Федерации, субъекта Российской Федерации)',
  '558401020500': 'Документ о предоставлении земельных участков',
  '558401020600': 'Решение об образовании земельных участков',
  '558401020700': 'Акт об установлении сервитута',
  '558401020800': 'Акт об установлении ограничения (обременения)',
  '558401029900': 'Иные акты органов государственной власти или органов местного самоуправления',
  '558401030000': 'Свидетельство о праве на наследство',
  '558401030100': 'Свидетельство о праве на наследство по закону',
  '558401030200': 'Свидетельство о праве на наследство по завещанию',
  '558401040000': 'Вступившие в силу судебные акты',
  '558401040100': 'Судебный акт, устанавливающий запрет на совершение действий в сфере государственного кадастрового учета в отношении объекта недвижимости',
  '558401040200': 'Судебный акт о восстановлении аннулированных и исключенных из государственного кадастра недвижимости сведений об объекте недвижимости',
  '558401040300': 'Судебный акт об установлении обременения (ограничении) в использовании объекта недвижимости (части объекта недвижимости)',
  '558401049900': 'Иные предусмотренные законодательством судебные акты',
  '558401050000': 'Акты (свидетельства) о правах на объекты недвижимости',
  '558401050100': 'Регистрационное удостоверение',
  '558401050200': 'Свидетельство о праве на землю',
  '558401050300': 'Государственный акт о праве на землю',
  '558401050400': 'Свидетельство о праве собственности',
  '558401059900': 'Иные акты, свидетельства о правах на объекты недвижимости',
  '558401060000': 'Документы на вновь возведенный (реконструированный) объект',
  '558401060100': 'Разрешение на ввод объекта в эксплуатацию',
  '558401060200': 'Разрешение на строительство',
  '558401060300': 'Акт ввода в эксплуатацию',
  '558401060400': 'Копия решения о создании искусственного земельного участка',
  '558401070000': 'Документы при упрощенном порядке оформления прав граждан на отдельные объекты недвижимого имущества',
  '558401070100': 'Выписка из похозяйственной книги о наличии у гражданина права на земельный участок',
  '558401070200': 'Кадастровый паспорт объекта недвижимости',
  '558401070300': 'Декларация об объекте недвижимого имущества',
  '558401080000': 'Прочие правоустанавливающие документы',
  '558401080100': 'Передаточный акт',
  '558401080200': 'Разделительный баланс',
  '558401080300': 'План приватизации',
  '558401080400': 'Правила доверительного управления',
  '558401080500': 'Акт описи и ареста имущества',
  '558401080600': 'Протокол о выделе земельного участка в счет земельной доли',
  '558401080700': 'Протокол общего собрания собственников помещений в многоквартирном доме',
  '558401080800': 'Отказ одаряемого принять дар (отказ от дара)',
  '558401080900': 'Соглашение о новации',
  '558401081000': 'Соглашение о разделе наследственного имущества',
  '558401081100': 'Соглашение об уплате алиментов',
  '558401081200': 'Документ, подтверждающий наличие, возникновение, переход ограничения (обременения) вещного права на объект недвижимости в пользу заявителя',
  '558401089900': 'Иной документ',
  '558402000000': 'СОПУТСТВУЮЩИЕ И ДОПОЛНИТЕЛЬНЫЕ ДОКУМЕНТЫ',
  '558402010000': 'Согласие супруга на совершение сделки',
  '558402020000': 'Согласие органов опеки и попечительства',
  '558402030000': 'Свидетельство о заключении брака',
  '558402040000': 'Свидетельство о расторжении брака',
  '558402050000': 'Решение об одобрении (о совершении) сделки',
  '558402060000': 'Справка о стоимости (справка о соотношении стоимости отчуждаемого объекта и балансовой стоимости имущества или активов хозяйственного общества)',
  '558402070000': 'Справка о заинтересованных лицах в сделке',
  '558402080000': 'Выписка из домовой книги (сведения о лицах, сохраняющих право проживания в жилом помещении после перехода права на него)',
  '558402090000': 'Выписка из лицевого счета (сведения о лицах, сохраняющих право проживания в жилом помещении после перехода права на него)',
  '558402100000': 'Протокол о результатах торгов',
  '558402110000': 'Лицензия (лицензия на осуществление определенных видов деятельности)',
  '558402120000': 'Проектная декларация',
  '558402130000': 'Отказ от преимущественного права покупки (для участников долевой собственности, для субъекта Российской Федерации при продаже земельного участка из состава земель сельскохозяйственного назначения)',
  '558402140000': 'Решение о создании юридического лица',
  '558402150000': 'Решение о реорганизации юридического лица',
  '558402160000': 'Решение о ликвидации юридического лица',
  '558402170000': 'Решение об утверждении оценки имущества (решение об утверждении оценки неденежных вкладов в уставный капитал общества)',
  '558402180000': 'Исполнительный лист',
  '558402190000': 'Договор поручения',
  '558402200000': 'Бухгалтерский баланс',
  '558402210000': 'Закладная',
  '558402220000': 'Добавочный лист к закладной',
  '558402230000': 'Выписка из реестра муниципальной собственности',
  '558402240000': 'Выписка из реестра собственности субъекта Российской Федерации',
  '558402250000': 'Выписка из реестра федерального имущества',
  '558402260000': 'Жилищный сертификат',
  '558402270000': 'Аудиторское заключение',
  '558402280000': 'Отчет о проведении независимой оценки (акт оценки)',
  '558402390000': 'Акт инвентаризации',
  '558402400000': 'Охранное обязательство собственника объекта культурного наследия',
  '558402990000': 'Иной документ',
  '558403000000': 'ДОКУМЕНТЫ, СОДЕРЖАЩИЕ СВЕДЕНИЯ ЕГРП',
  '558403010000': 'Свидетельство о государственной регистрации права',
  '558403020000': 'Выписка о правах на объект недвижимого имущества',
  '558403030000': 'Выписка о переходе прав на объект недвижимого имущества',
  '558403040000': 'Выписка о правах отдельного лица на имеющиеся у него объекты недвижимого имущества',
  '558403050000': 'Выписка о признании правообладателя недееспособным или ограниченно дееспособным',
  '558403060000': 'Справка о содержании правоустанавливающих документов на объект недвижимого имущества',
  '558403070000': 'Справка о лицах, получивших сведения об объекте недвижимого имущества',
  '558403080000': 'Копии договоров и иных документов, выражающих содержание односторонних сделок, совершенных в простой письменной форме',
  '558403090000': 'Выписка из ЕГРП о принятии на учёт бесхозяйного объекта недвижимого имущества',
  '558403100000': 'Выписка из ЕГРП о принятии на учёт бесхозяйного объекта недвижимого имущества, от права собственности на который собственник отказался',
  '558403110000': 'Выписка из ЕГРП о бесхозяйном объекте недвижимого имущества, принятом на учёт',
  '558403120000': 'Выписка из единого государственного реестра прав на недвижимое имущество и сделок с ним, удостоверяющая проведение государственной регистрации возникновения и (или) перехода прав на недвижимое имущество',
  '558403990000': 'Иной документ',
  '558404000000': 'Соглашения и согласия об образовании земельных участков',
  '558404010000': 'Соглашение о разделе, об объединении, о перераспределении земельных участков или о выделе из земельных участков',
  '558404020000': 'Согласие на образование земельных участков',
  '558404030000': 'Соглашение об образовании общей долевой собственности на земельный участок, образуемый при выделе его в счет земельной доли или земельных долей',
  '558404040000': 'Соглашение об образовании общей совместной собственности на земельный участок, образуемый при выделе его в счет земельной доли или земельных долей',
  '558405000000': 'Документы, подтверждающие возникновение права собственности в отношении земельного участка в силу федерального закона вне зависимости от момента государственной регистрации этого права в ЕГРП',
  '558405010000': 'Копия решения о безвозмездной передаче военного недвижимого имущества из федеральной собственности в собственность субъекта Российской Федерации - города федерального значения Москвы или Санкт-Петербурга либо муниципальную собственность',
  '558405020000': 'Подлинник или копия письма органа исполнительной власти субъекта Российской Федерации - города федерального значения Москвы или Санкт-Петербурга либо органа местного самоуправления муниципального образования о согласовании границ образуемого земельного участка с приложением согласованного проекта границ земельного участка в случае, если акт согласования границ земельного участка не заверен подписью уполномоченного должностного лица соответствующего органа',
  '558405030000': 'Решение об изъятии земельных участков и (или) расположенных на них иных объектов недвижимого имущества в целях размещения олимпийских объектов',
  '558405040000': 'Копия акта приема-передачи объектов недвижимого имущества, в отношении которых принято решение об изъятии в целях размещения олимпийских объектов',
  '558405990000': 'Иные документы, подтверждающие возникновение права собственности в отношении земельного участка в силу федерального закона вне зависимости от момента государственной регистрации этого права в ЕГРП',
  '558500000000': 'Решения, уведомления, расписки',
  '558501000000': 'Документы о результатах государственного кадастрового учёта',
  '558501010000': 'РЕШЕНИЯ',
  '558501010100': 'Решение об отказе в предоставлении запрашиваемых сведений',
  '558501010200': 'Решение об отказе в исправлении кадастровой ошибки',
  '558501010300': 'Решение об отказе в исправлении технической ошибки',
  '558501010400': 'Решение об исправлении технической и кадастровой ошибок',
  '558501010500': 'Решение об отказе в осуществлении кадастровой процедуры',
  '558501010600': 'Решение о приостановлении осуществления кадастровой процедуры',
  '558501010700': 'Решение об отказе в снятии приостановления',
  '558501010800': 'Решение о снятии с кадастрового учёта земельных участков со статусом «временные» по заявлению заинтересованного лица',
  '558501010900': 'Решение об отказе в выдаче кадастрового паспорта',
  '558501011000': 'Решение об осуществлении кадастровой процедуры',
  '558501011100': 'Решение об аннулировании кадастровых сведений',
  '558501011200': 'Решение об отклонении заявления об исправлении технической ошибки',
  '558501011300': 'Решение о внесении записи о наличии возражения',
  '558501011400': 'Решение о погашении записи о наличии возражения',
  '558501011500': 'Решение о внесении записи о заявлении о невозможности государственной регистрации',
  '558501011600': 'Решение о погашении записи о заявлении о невозможности государственной регистрации',
  '558501011700': 'Решение о необходимости устранения кадастровой ошибки в сведениях ГКН',
  '558501011701': 'Решение о необходимости устранения кадастровой ошибки в сведениях ГКН о местоположении границ земельного участка',
  '558501020000': 'УВЕДОМЛЕНИЯ',
  '558501020100': 'Уведомление об отсутствии в ГКН запрашиваемых сведений',
  '558501020200': 'Уведомление об осуществлённом кадастровом учёте',
  '558501020300': 'Уведомление об отказе в приеме документов для внесения в Единый государственный реестр объектов капитального строительства (далее - Единый государственный реестр) сведений об ОКС',
  '558501020400': 'Уведомление об отказе в приеме документов для предоставления из Единого государственного реестра сведений об ОКС',
  '558501020500': 'Уведомление об устранении технической ошибки в сведениях ГКН',
  '558501020600': 'Уведомление об отказе в выдаче сведений об ОКС',
  '558501020700': 'Уведомление о приостановлении осуществления кадастровой процедуры',
  '558501020800': 'Уведомление об отказе в осуществлении кадастровой процедуры',
  '558501020900': 'Уведомление о регистрации права',
  '558501021000': 'Уведомление о приостановлении принятия на учёт имущества в качестве бесхозяйного',
  '558501021100': 'Сообщение об отказе в принятии на учёт имущества в качестве бесхозяйного',
  '558501021200': 'Уведомление о снятии с учёта имущества в качестве бесхозяйного',
  '558501021300': 'Уведомление о возврате заявления о наличии возражения в отношении зарегистрированного права на объект недвижимости без рассмотрения',
  '558501021400': 'Уведомление о неполучении и нерассмотрении запроса о предоставлении сведений ГКН',
  '558501030000': 'ПРОЧИЕ',
  '558501030100': 'Расписка в получении соответствующих документов для проведения государственного кадастрового учёта',
  '558501030200': 'Протокол проверки документов',
  '558501030300': 'Акт определения кадастровой стоимости земельных участков',
  '558501030400': 'Протокол выявления технической ошибки',
  '558501030500': 'Акт определения кадастровой стоимости объектов недвижимости',
  '558501030600': 'Протокол выявления кадастровой ошибки',
  '558501030601': 'Протокол выявления кадастровой ошибки в сведениях ГКН о местоположении границ земельного участка',
  '558501030700': 'Документ, содержащий сведения о расчете среднего (взвешенного по площади) значения УПКС помещений',
  '558501030800': 'Протокол проверок сведений о земельных участках (верификация)',
  '558501030900': 'Протокол проверок сведений о зданиях, сооружениях, помещениях, объектах незавершенного строительства (верификация)',
  '558501031000': 'Протокол проверок пространственных данных кадастра (верификация)',
  '558501039900': 'Иные документы',
  '558502000000': 'ДОКУМЕНТЫ О РЕЗУЛЬТАТАХ РЕГИСТРАЦИИ ПРАВ',
  '558502010000': 'УВЕДОМЛЕНИЯ, СООБЩЕНИЯ',
  '558502010100': 'Уведомление о приостановлении государственной регистрации прав',
  '558502010200': 'Уведомление о прекращении государственной регистрации прав',
  '558502010300': 'Уведомление о зарегистрированных ограничениях (обременениях) прав',
  '558502010400': 'Уведомление о внесении изменений в ЕГРП',
  '558502010500': 'Уведомление об исправлении технической ошибки в записях единого государственного реестра прав на недвижимое имущество и сделок с ним',
  '558502010600': 'Сообщение об отказе в государственной регистрации прав',
  '558502010700': 'Сообщение об отказе в предоставлении сведений из ЕГРП',
  '558502010800': 'Уведомление об отсутствии в ЕГРП запрашиваемых сведений',
  '558502010900': 'Сообщение о приёме заявления о государственной регистрации права, ограничения (обременения) права на предприятие, сделки с ним',
  '558502011000': 'Сообщение о поступлении документов на государственную регистрацию договоров мены и (или) перехода права',
  '558502011100': 'Уведомление о возврате приложенных к заявлению документов без рассмотрения',
  '558502011200': 'Уведомление о государственной регистрации прекращения ограничения (обременения)',
  '558502011300': 'Уведомление об отсутствии сведений о лицах, получивших сведения об объектах недвижимого имущества',
  '558502011400': 'Уведомление о государственной регистрации права собственности субъекта Российской Федерации или муниципального образования на земельный участок или земельную долю вследствие отказа от соответствующего права правообладателем',
  '558502011500': 'Уведомление о поступлении решения суда, вступившего в законную силу, с указанием срока окончания осуществления государственной регистрации прав, в случае если отказ в государственной регистрации прав признан судом необоснованным',
  '558502011600': 'Уведомление о внесении изменений в единый государственный реестр прав на недвижимое имущество и сделок с ним в связи с изменением содержащихся в базовых государственных информационных ресурсах сведений о правообладателе (правообладателях)',
  '558502011700': 'Уведомление о возврате документов без рассмотрения при отказе в осуществлении государственного кадастрового учета',
  '558502011800': 'Уведомление о неполучении и нерассмотрении запроса о предоставлении сведений ЕГРП',
  '558502011900': 'Уведомление о непринятии заявления и документов, необходимых для государственной регистрации прав, к рассмотрению.',
  '558502020000': 'ПРОЧИЕ',
  '558502020100': 'Расписка в получении документов на государственную регистрацию прав',
  '558502029900': 'Иной документ',
  '558601000000': 'Расписка в получении извещений о проведении собрания о согласовании местоположения границ',
  '558602000000': 'Уведомление о вручении извещения о проведении собрания о согласовании местоположения границ',
  '558603000000': 'Копия страницы печатного издания, содержащей извещение о проведении собрания о согласовании местоположения границ земельного участка, включая первый лист, содержащего реквизиты такого печатного издания',
  '558699000000': 'Иные документы, свидетельствующие о соблюдении установленного порядка извещения заинтересованных лиц о проведении собрания о согласовании местоположения границ земельного участка',
}

export const printCulturalHeritage = cultHeritage => {
  if (!cultHeritage) return 'данные отсутствуют'
  const parts = []
  if (cultHeritage.InclusionEGROKN) {
    parts.push('Является объектом культурного наследия')
    parts.push(`№${cultHeritage.InclusionEGROKN.ch_RegNum}`)
    parts.push(`${culturals[cultHeritage.InclusionEGROKN.ch_ObjCultural]}`)
    parts.push(`${cultHeritage.InclusionEGROKN.ch_NameCultural}`)
  } else if (cultHeritage.AssignmentEGROKN) {
    parts.push('Является выявленным объектом культурного наследия')
    parts.push(`№${cultHeritage.AssignmentEGROKN.ch_RegNum}`)
    parts.push(`${culturals[cultHeritage.AssignmentEGROKN.ch_ObjCultural]}`)
    parts.push(`${cultHeritage.AssignmentEGROKN.ch_NameCultural}`)
  }
  if (cultHeritage.Document.doc_Name) {
    parts.push(`${cultHeritage.Document.doc_Name}`)
  } else {
    parts.push(docTypes[cultHeritage.Document.doc_CodeDocument])
  }

  if (cultHeritage.Document.doc_Name.indexOf(cultHeritage.Document.doc_Number) === -1) {
    parts.push(`№${cultHeritage.Document.doc_Number}`)
  } else if (cultHeritage.Document.doc_Name.indexOf(`№${cultHeritage.Document.doc_Number}`) === -1) {
    parts[parts.length - 1] = parts[parts.length - 1].replace(cultHeritage.Document.doc_Number, `№${cultHeritage.Document.doc_Number}`)
  }
  parts[parts.length - 1] += ` от ${printDate(cultHeritage.Document.doc_Date)}`

  return parts.join(', ')
}

export const printRegistration = registration => {
  if (!registration) return ''
  if (registration.Name) return registration.Name
  return ''
}

export const UNIT_MEASURE_QUAL = {
  '003': 'мм',
  '004': 'см',
  '005': 'дм',
  '006': 'м',
  '008': 'км',
  '050': 'мм²',
  '051': 'см²',
  '053': 'дм²',
  '055': 'м²',
  '058': 'тыс. м²',
  '059': 'Га',
  '061': 'км²',
  '383': 'руб.',
  '384': 'тыс. руб.',
  '385': 'млн. руб.',
  '386': 'млрд. руб.',
}

export const printArea = area => {
  if (!area) return ''
  if (!area.Area) return ''
  let inaccuracy = ''
  if (area.Inaccuracy) {
    inaccuracy = ` +/- ${area.Inaccuracy}`
  }
  return `${area.Area} м²${inaccuracy}`
}

export const printCadastralBlock = obj => {
  if (obj.CadastralBlock) return obj.CadastralBlock
  if (obj.CadastralNumber) {
    const parts = obj.CadastralNumber.split(':')
    parts.pop()
    return parts.join(':')
  }
  return 'данные отсутствуют'
}

export const categoriesZU = {
  '003001000000': 'Земли сельскохозяйственного назначения',
  '003002000000': 'Земли населённых пунктов',
  '003003000000': 'Земли промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, земли для обеспечения космической деятельности, земли обороны, безопасности и земли иного специального назначения',
  '003004000000': 'Земли особо охраняемых территорий и объектов',
  '003005000000': 'Земли лесного фонда',
  '003006000000': 'Земли водного фонда',
  '003007000000': 'Земли запаса',
  '003008000000': 'Категория не установлена',
}

export const printCategoryZU = obj => {
  if (!obj || !obj.Category) return 'данные отсутствуют'
  return categoriesZU[obj.Category]
}

export const ZUNames = {
  '01': 'Землепользование',
  '02': 'Единое землепользование',
  '03': 'Обособленный участок',
  '04': 'Условный участок',
  '05': 'Многоконтурный участок',
  '06': 'Значение отсутствует',
}

export const printZUName = obj => {
  if (!obj || !obj.Name) return ''
  if (obj.Name === '06') return ''
  return `(${ZUNames[obj.Name]})`
}

export const printCoordSystems = obj => {
  if (!obj.CoordSystems || !obj.CoordSystems.spa_CoordSystem) return 'данные отсутствуют'
  let arr = []
  if (obj.CoordSystems.spa_CoordSystem.constructor.name === 'Array') {
    arr = obj.CoordSystems.spa_CoordSystem
  }
  if (obj.CoordSystems.spa_CoordSystem.constructor.name === 'Object') {
    arr.push(obj.CoordSystems.spa_CoordSystem)
  }
  if (arr && arr.length > 0) {
    return arr.map(ii => ii.Name).join('; ')
  }

  return 'данные отсутствуют'
}

export const utilizations = {
  '141000000000': 'Для размещения объектов сельскохозяйственного назначения и сельскохозяйственных угодий',
  '141001000000': 'Для сельскохозяйственного производства',
  '141001010000': 'Для использования в качестве сельскохозяйственных угодий',
  '141001020000': 'Для размещения зданий, строений, сооружений, используемых для производства, хранения и первичной переработки сельскохозяйственной продукции',
  '141001030000': 'Для размещения внутрихозяйственных дорог и коммуникаций',
  '141001040000': 'Для размещения водных объектов',
  '141002000000': 'Для ведения крестьянского (фермерского) хозяйства',
  '141003000000': 'Для ведения личного подсобного хозяйства',
  '141004000000': 'Для ведения гражданами садоводства и огородничества',
  '141005000000': 'Для ведения гражданами животноводства',
  '141006000000': 'Для дачного строительства',
  '141007000000': 'Для размещения древесно-кустарниковой растительности, предназначенной для защиты земель от воздействия негативных (вредных) природных, антропогенных и техногенных явлений',
  '141008000000': 'Для научно-исследовательских целей',
  '141009000000': 'Для учебных целей',
  '141010000000': 'Для сенокошения и выпаса скота гражданами',
  '141011000000': 'Фонд перераспределения',
  '141012000000': 'Для размещения объектов охотничьего хозяйства',
  '141013000000': 'Для размещения объектов рыбного хозяйства',
  '141014000000': 'Для иных видов сельскохозяйственного использования',
  '142000000000': 'Для размещения объектов, характерных для населённых пунктов',
  '142001000000': 'Для объектов жилой застройки',
  '142001010000': 'Для индивидуальной жилой застройки',
  '142001020000': 'Для многоквартирной застройки',
  '142001020100': 'Для малоэтажной застройки',
  '142001020200': 'Для среднеэтажной застройки',
  '142001020300': 'Для многоэтажной застройки',
  '142001020400': 'Для иных видов жилой застройки',
  '142001030000': 'Для размещения объектов дошкольного, начального, общего и среднего (полного) общего образования',
  '142001040000': 'Для размещения иных объектов, допустимых в жилых зонах и не перечисленных в классификаторе',
  '142002000000': 'Для объектов общественно-делового значения',
  '142002010000': 'Для размещения объектов социального и коммунально-бытового назначения',
  '142002020000': 'Для размещения объектов здравоохранения',
  '142002030000': 'Для размещения объектов культуры',
  '142002040000': 'Для размещения объектов торговли',
  '142002040100': 'Для размещения объектов розничной торговли',
  '142002040200': 'Для размещения объектов оптовой торговли',
  '142002050000': 'Для размещения объектов общественного питания',
  '142002060000': 'Для размещения объектов предпринимательской деятельности',
  '142002070000': 'Для размещения объектов среднего профессионального и высшего профессионального образования',
  '142002080000': 'Для размещения административных зданий',
  '142002090000': 'Для размещения научно-исследовательских учреждений',
  '142002100000': 'Для размещения культовых зданий',
  '142002110000': 'Для стоянок автомобильного транспорта',
  '142002120000': 'Для размещения объектов делового назначения, в том числе офисных центров',
  '142002130000': 'Для размещения объектов финансового назначения',
  '142002140000': 'Для размещения гостиниц',
  '142002150000': 'Для размещения подземных или многоэтажных гаражей',
  '142002160000': 'Для размещения индивидуальных гаражей',
  '142002170000': 'Для размещения иных объектов общественно-делового значения, обеспечивающих жизнь граждан',
  '142003000000': 'Для общего пользования (уличная сеть)',
  '142004000000': 'Для размещения объектов специального назначения',
  '142004010000': 'Для размещения кладбищ',
  '142004020000': 'Для размещения крематориев',
  '142004030000': 'Для размещения скотомогильников',
  '142004040000': 'Под объектами размещения отходов потребления',
  '142004050000': 'Под иными объектами специального назначения',
  '142005000000': 'Для размещения коммунальных, складских объектов',
  '142006000000': 'Для размещения объектов жилищно-коммунального хозяйства',
  '142007000000': 'Для иных видов использования, характерных для населённых пунктов',
  '143000000000': 'Для размещения объектов промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, обеспечения космической деятельности, обороны, безопасности и иного специального назначения',
  '143001000000': 'Для размещения промышленных объектов',
  '143001010000': 'Для размещения производственных и административных зданий, строений, сооружений и обслуживающих их объектов',
  '143001010100': 'Для размещения производственных зданий',
  '143001010200': 'Для размещения коммуникаций',
  '143001010300': 'Для размещения подъездных путей',
  '143001010400': 'Для размещения складских помещений',
  '143001010500': 'Для размещения административных зданий',
  '143001010600': 'Для размещения культурно-бытовых зданий',
  '143001010700': 'Для размещения иных сооружений промышленности',
  '143001020000': 'Для добычи и разработки полезных ископаемых',
  '143001030000': 'Для размещения иных объектов промышленности',
  '143002000000': 'Для размещения объектов энергетики',
  '143002010000': 'Для размещения электростанций и обслуживающих сооружений и объектов',
  '143002010100': 'Для размещения гидроэлектростанций',
  '143002010200': 'Для размещения атомных станций',
  '143002010300': 'Для размещения ядерных установок',
  '143002010400': 'Для размещения пунктов хранения ядерных материалов и радиоактивных веществ энергетики',
  '143002010500': 'Для размещения хранилищ радиоактивных отходов',
  '143002010600': 'Для размещения тепловых станций',
  '143002010700': 'Для размещения иных типов электростанций',
  '143002010800': 'Для размещения иных обслуживающих сооружений и объектов',
  '143002020000': 'Для размещения объектов электросетевого хозяйства',
  '143002020100': 'Для размещения воздушных линий электропередачи',
  '143002020200': 'Для размещения наземных сооружений кабельных линий электропередачи',
  '143002020300': 'Для размещения подстанций',
  '143002020400': 'Для размещения распределительных пунктов',
  '143002020500': 'Для размещения других сооружений и объектов электросетевого хозяйства',
  '143002030000': 'Для размещения иных объектов энергетики',
  '143003000000': 'Для размещения объектов транспорта',
  '143003010000': 'Для размещения и эксплуатации объектов железнодорожного транспорта',
  '143003010100': 'Для размещения железнодорожных путей и их конструктивных элементов',
  '143003010200': 'Для размещения полос отвода железнодорожных путей',
  '143003010300': 'Для размещения, эксплуатации, расширения и реконструкции строений, зданий, сооружений, в том числе железнодорожных вокзалов, железнодорожных станций, а также устройств и других объектов, необходимых для эксплуатации, содержания, строительства, реконструкции, ремонта, развития наземных и подземных зданий, строений, сооружений, устройств и других объектов железнодорожного транспорта',
  '143003010301': 'Для размещения железнодорожных вокзалов',
  '143003010302': 'Для размещения железнодорожных станций',
  '143003010303': 'Для размещения устройств и других объектов, необходимых для эксплуатации, содержания, строительства, реконструкции, ремонта, развития наземных и подземных зданий, строений, сооружений, устройств и других объектов железнодорожного транспорта',
  '143003020000': 'Для размещения и эксплуатации объектов автомобильного транспорта и объектов дорожного хозяйства',
  '143003020100': 'Для размещения автомобильных дорог и их конструктивных элементов',
  '143003020200': 'Для размещения полос отвода',
  '143003020300': 'Для размещения объектов дорожного сервиса в полосах отвода автомобильных дорог',
  '143003020400': 'Для размещения дорожных сооружений',
  '143003020500': 'Для размещения автовокзалов и автостанций',
  '143003020600': 'Для размещения иных объектов автомобильного транспорта и дорожного хозяйства',
  '143003030000': 'Для размещения и эксплуатации объектов морского, внутреннего водного транспорта',
  '143003030100': 'Для размещения искусственно созданных внутренних водных путей',
  '143003030200': 'Для размещения морских и речных портов, причалов, пристаней',
  '143003030300': 'Для размещения иных объектов морского, внутреннего водного транспорта',
  '143003030400': 'Для выделения береговой полосы',
  '143003040000': 'Для размещения и эксплуатации объектов воздушного транспорта',
  '143003040100': 'Для размещения аэропортов и аэродромов',
  '143003040200': 'Для размещения аэровокзалов',
  '143003040300': 'Для размещения взлетно-посадочных полос',
  '143003040400': 'Для размещения иных наземных объектов воздушного транспорта',
  '143003050000': 'Для размещения и эксплуатации объектов трубопроводного транспорта',
  '143003050100': 'Для размещения нефтепроводов',
  '143003050200': 'Для размещения газопроводов',
  '143003050300': 'Для размещения иных трубопроводов',
  '143003050400': 'Для размещения иных объектов трубопроводного транспорта',
  '143003060000': 'Для размещения и эксплуатации иных объектов транспорта',
  '143004000000': 'Для размещения объектов связи, радиовещания, телевидения, информатики',
  '143004010000': 'Для размещения эксплуатационных предприятий связи и обслуживания линий связи',
  '143004020000': 'Для размещения кабельных, радиорелейных и воздушных линий связи и линий радиофикации на трассах кабельных и воздушных линий связи и радиофикации и их охранные зоны',
  '143004030000': 'Для размещения подземных кабельных и воздушных линий связи и радиофикации и их охранные зоны',
  '143004040000': 'Для размещения наземных и подземных необслуживаемых усилительных пунктов на кабельных линиях связи и их охранные зоны',
  '143004050000': 'Для размещения наземных сооружений и инфраструктур спутниковой связи',
  '143004060000': 'Для размещения иных объектов связи, радиовещания, телевидения, информатики',
  '143005000000': 'Для размещения объектов, предназначенных для обеспечения космической деятельности',
  '143005010000': 'Для размещения космодромов, стартовых комплексов и пусковых установок',
  '143005020000': 'Для размещения командно-измерительных комплексов, центров и пунктов управления полетами космических объектов, приема, хранения и переработки информации',
  '143005030000': 'Для размещения баз хранения космической техники',
  '143005040000': 'Для размещения полигонов приземления космических объектов и взлетно-посадочных полос',
  '143005050000': 'Для размещения объектов экспериментальной базы для отработки космической техники',
  '143005060000': 'Для размещения центров и оборудования для подготовки космонавтов',
  '143005070000': 'Для размещения других наземных сооружений и техники, используемых при осуществлении космической деятельности',
  '143006000000': 'Для размещения объектов, предназначенных для обеспечения обороны и безопасности',
  '143006010000': 'Для обеспечения задач обороны',
  '143006010100': 'Для размещения военных организаций, учреждений и других объектов',
  '143006010200': 'Для дислокации войск и сил флота',
  '143006010300': 'Для проведения учений и иных мероприятий',
  '143006010400': 'Для испытательных полигонов',
  '143006010500': 'Для мест уничтожения оружия и захоронения отходов',
  '143006010600': 'Для создания запасов материальных ценностей в государственном и мобилизационном резервах (хранилища, склады и другие)',
  '143006010700': 'Для размещения иных объектов обороны',
  '143006020000': 'Для размещения объектов (территорий), обеспечивающих защиту и охрану Государственной границы Российской Федерации',
  '143006020100': 'Для обустройства и содержания инженерно-технических сооружений и заграждений',
  '143006020200': 'Для обустройства и содержания пограничных знаков',
  '143006020300': 'Для обустройства и содержания пограничных просек',
  '143006020400': 'Для обустройства и содержания коммуникаций',
  '143006020500': 'Для обустройства и содержания пунктов пропуска через Государственную границу Российской Федерации',
  '143006020600': 'Для размещения иных объектов для защиты и охраны Государственной границы Российской Федерации',
  '143006030000': 'Для размещения иных объектов обороны и безопасности',
  '143007000000': 'Для размещения иных объектов промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, обеспечения космической деятельности, обороны, безопасности и иного специального назначения',
  '144000000000': 'Для размещения особо охраняемых историко-культурных и природных объектов (территорий)',
  '144001000000': 'Для размещения особо охраняемых природных объектов (территорий)',
  '144001010000': 'Для размещения государственных природных заповедников (в том числе биосферных)',
  '144001020000': 'Для размещения государственных природных заказников',
  '144001030000': 'Для размещения национальных парков',
  '144001040000': 'Для размещения природных парков',
  '144001050000': 'Для размещения дендрологических парков',
  '144001060000': 'Для размещения ботанических садов',
  '144001070000': 'Для размещения объектов санаторного и курортного назначения',
  '144001080000': 'Территории месторождений минеральных вод, лечебных грязей, рапы лиманов и озер',
  '144001090000': 'Для традиционного природопользования',
  '144001100000': 'Для размещения иных особо охраняемых природных территорий (объектов)',
  '144002000000': 'Для размещения объектов (территорий) природоохранного назначения',
  '144003000000': 'Для размещения объектов (территорий) рекреационного назначения',
  '144003010000': 'Для размещения домов отдыха, пансионатов, кемпингов',
  '144003020000': 'Для размещения объектов физической культуры и спорта',
  '144003030000': 'Для размещения туристических баз, стационарных и палаточных туристско-оздоровительных лагерей, домов рыболова и охотника, детских туристических станций',
  '144003040000': 'Для размещения туристических парков',
  '144003050000': 'Для размещения лесопарков',
  '144003060000': 'Для размещения учебно-туристических троп и трасс',
  '144003070000': 'Для размещения детских и спортивных лагерей',
  '144003080000': 'Для размещения скверов, парков, городских садов',
  '144003090000': 'Для размещения пляжей',
  '144003100000': 'Для размещения иных объектов (территорий) рекреационного назначения',
  '144004000000': 'Для размещения объектов историко-культурного назначения',
  '144004010000': 'Для размещения объектов культурного наследия народов Российской Федерации (памятников истории и культуры), в том числе объектов археологического наследия',
  '144004020000': 'Для размещения военных и гражданских захоронений',
  '144005000000': 'Для размещения иных особо охраняемых историко-культурных и природных объектов (территорий)',
  '145000000000': 'Для размещения объектов лесного фонда',
  '145001000000': 'Для размещения лесной растительности',
  '145002000000': 'Для восстановления лесной растительности',
  '145003000000': 'Для прочих объектов лесного хозяйства',
  '146000000000': 'Для размещения объектов водного фонда',
  '146001000000': 'Под водными объектами',
  '146002000000': 'Для размещения гидротехнических сооружений',
  '146003000000': 'Для размещения иных сооружений, расположенных на водных объектах',
  '147000000000': 'Земли запаса (неиспользуемые)',
}

export const printUtilization = obj => {
  if (!obj.Utilization) return 'данные отсутствуют'
  if (obj.Utilization.ByDoc) {
    return obj.Utilization.ByDoc
  }
  if (obj.Utilization.Utilization && utilizations[obj.Utilization.Utilization]) {
    return utilizations[obj.Utilization.Utilization]
  }
  return 'данные отсутствуют'
}

export const libNO = {
  '233001000000': 'Лес',
  '233002000000': 'Водный объект',
  '233003000000': 'Иной природный объект',
}

export const libForestUse = {
  '234001000000': 'Защитные леса',
  '234002000000': 'Эксплуатационные леса',
  '234003000000': 'Резервные леса',
}

export const libForestEncumbrances = {
  '235001000000': 'Заготовка древесины',
  '235002000000': 'Заготовка живицы',
  '235003000000': 'Заготовка и сбор недревесных лесных ресурсов',
  '235004000000': 'Заготовка пищевых лесных ресурсов и сбор лекарственных растений',
  '235005000000': 'Осуществление видов деятельности в сфере охотничьего хозяйства',
  '235006000000': 'Ведение сельского хозяйства',
  '235007000000': 'Осуществление научно-исследовательской деятельности, образовательной деятельности',
  '235008000000': 'Осуществление рекреационной деятельности',
  '235009000000': 'Создание лесных плантаций и их эксплуатация',
  '235010000000': 'Выращивание лесных плодовых, ягодных, декоративных растений, лекарственных растений',
  '235010001000': 'Выращивание посадочного материала лесных растений (саженцев, сеянцев)',
  '235011000000': 'Выполнение работ по геологическому изучению недр, разработка месторождений полезных ископаемых',
  '235012000000': 'Строительство и эксплуатация водохранилищ и иных искусственных водных объектов, а также гидротехнических сооружений, морских портов, морских терминалов, речных портов, причалов',
  '235013000000': 'Строительство, реконструкция, эксплуатация линейных объектов',
  '235014000000': 'Переработка древесины и иных лесных ресурсов',
  '235015000000': 'Осуществление религиозной деятельности',
  '235099000000': 'Иные виды, определенные в соответствии с частью 2 статьи 6 Лесного кодекса',
}

export const libForestCategoryProtective = {
  '236001000000': 'Леса, расположенные на особо охраняемых природных территориях',
  '236002000000': 'Леса, расположенные в водоохранных зонах',
  '236003000000': 'Леса, выполняющие функции защиты природных и иных объектов',
  '236003001000': 'Леса, расположенные в первом и втором поясах зон санитарной охраны источников питьевого и хозяйственно-бытового водоснабжения',
  '236003002000': 'Защитные полосы лесов, расположенные вдоль железнодорожных путей общего пользования, федеральных автомобильных дорог общего пользования, автомобильных дорог общего пользования, находящихся в собственности субъектов Российской Федерации',
  '236003003000': 'Зеленые зоны',
  '236003004000': 'Лесопарковые зоны',
  '236003005000': 'Городские леса',
  '236003006000': 'Леса, расположенные в первой, второй и третьей зонах округов санитарной (горно-санитарной) охраны лечебно-оздоровительных местностей и курортов',
  '236004000000': 'Ценные леса',
  '236004001000': 'Государственные защитные лесные полосы',
  '236004002000': 'Противоэрозионные леса',
  '236004003000': '>Леса, расположенные в пустынных, полупустынных, лесостепных, лесотундровых зонах, степях, горах',
  '236004004000': 'Леса, имеющие научное или историческое значение',
  '236004005000': 'Орехово-промысловые зоны',
  '236004006000': 'Лесные плодовые насаждения',
  '236004007000': 'Ленточные боры',
  '236004008000': 'Запретные полосы лесов, расположенные вдоль водных объектов',
  '236004009000': 'Нерестоохранные полосы лесов',
  '236005000000': 'Иная категория',
}

export const printNaturalObject = obj => {
  if (!obj || !obj.NaturalObjects || !obj.NaturalObjects.nobj_NaturalObject) return 'данные отсутствуют'
  const arr = []
  const nobj = obj.NaturalObjects.nobj_NaturalObject
  if (nobj.nobj_Kind) arr.push(`${libNO[nobj.nobj_Kind]}`)
  if (nobj.nobj_Forestry) arr.push(`Наименование: ${nobj.nobj_Forestry.split(',').filter(ii => !!ii.trim()).join(',').trim()}`)
  if (nobj.nobj_ForestUse) arr.push(`Целевое назначение (категория) лесов: ${libForestUse[nobj.nobj_ForestUse]}`)
  if (nobj.nobj_ProtectiveForest) {
    if (libForestCategoryProtective[nobj.nobj_ProtectiveForest]) {
      arr.push(`Категория защитных лесов: ${libForestCategoryProtective[nobj.nobj_ProtectiveForest]}`)
    } else {
      arr.push(`Категория защитных лесов: ${nobj.nobj_ProtectiveForest.trim()}`)
    }
  }
  if (nobj.nobj_QuarterNumbers) arr.push(`Номера лесных кварталов: ${nobj.nobj_QuarterNumbers.trim()}`)
  if (nobj.nobj_TaxationSeparations) arr.push(`Номера лесотаксационных выделов: ${nobj.nobj_TaxationSeparations.trim()}`)
  if (nobj.nobj_ForestEncumbrance) arr.push(`Виды разрешенного использования лесов: ${libForestEncumbrances[nobj.nobj_ForestEncumbrance]}`)
  if (nobj.nobj_WaterObject) arr.push(`Вид водного объекта: ${nobj.nobj_WaterObject.trim()}`)
  if (nobj.nobj_NameOther) arr.push(`Наименование: ${nobj.nobj_NameOther.trim()}`)
  if (nobj.nobj_CharOther) arr.push(`Характеристика: ${nobj.nobj_CharOther.trim()}`)
  if (arr && arr.length > 0) {
    return `${arr.join('. ')}.`
  }
  return 'данные отсутствуют'
}
